export const RECEIPT_STATUS_PENDING = "pending";
export const RECEIPT_STATUS_PENDING_LABEL = "Pendiente";
export const RECEIPT_STATUS_ERROR = "error";
export const RECEIPT_STATUS_ERROR_LABEL = "Error";
export const RECEIPT_STATUS_GENERATED = "generated";
export const RECEIPT_STATUS_GENERATED_LABEL = "Generado";
export const RECEIPT_STATUS_CANCELLATION_REQUESTED = "cancellation_requested";
export const RECEIPT_STATUS_CANCELLATION_REQUESTED_LABEL =
  "Anulación Pendiente";
export const RECEIPT_STATUS_CANCELLED = "cancelled";
export const RECEIPT_STATUS_CANCELLED_LABEL = "Anulado";
export const RECEIPT_STATUS_CANCELLATION_FAILED = "cancellation_failed";
export const RECEIPT_STATUS_CANCELLATION_FAILED_LABEL = "Anulación Fallida";

export const RECEIPT_STATUS_CHOICES: Map<string, string> = new Map([
  [RECEIPT_STATUS_PENDING, RECEIPT_STATUS_PENDING_LABEL],
  [RECEIPT_STATUS_ERROR, RECEIPT_STATUS_ERROR_LABEL],
  [RECEIPT_STATUS_GENERATED, RECEIPT_STATUS_GENERATED_LABEL],
  [
    RECEIPT_STATUS_CANCELLATION_REQUESTED,
    RECEIPT_STATUS_CANCELLATION_REQUESTED_LABEL,
  ],
  [RECEIPT_STATUS_CANCELLED, RECEIPT_STATUS_CANCELLED_LABEL],
  [
    RECEIPT_STATUS_CANCELLATION_FAILED,
    RECEIPT_STATUS_CANCELLATION_FAILED_LABEL,
  ],
]);

export const RECEIPT_PAYMENT_METHOD_CASH = "cash";
export const RECEIPT_PAYMENT_METHOD_CASH_LABEL = "Efectivo";
export const RECEIPT_PAYMENT_METHOD_CHECK = "check";
export const RECEIPT_PAYMENT_METHOD_CHECK_LABEL = "Cheque";
export const RECEIPT_PAYMENT_METHOD_BANK_TRANSFER = "bank_transfer";
export const RECEIPT_PAYMENT_METHOD_BANK_TRANSFER_LABEL =
  "Transferencia Bancaria";

export const RECEIPT_PAYMENT_METHOD_CHOICES: Map<string, string> = new Map([
  [RECEIPT_PAYMENT_METHOD_CASH, RECEIPT_PAYMENT_METHOD_CASH_LABEL],
  [RECEIPT_PAYMENT_METHOD_CHECK, RECEIPT_PAYMENT_METHOD_CHECK_LABEL],
  [
    RECEIPT_PAYMENT_METHOD_BANK_TRANSFER,
    RECEIPT_PAYMENT_METHOD_BANK_TRANSFER_LABEL,
  ],
]);

export interface Receipt {
  id?: number;
  taxPayerId?: number;
  establishmentId?: number;
  establishmentCode?: string;
  dispatchPointId?: number;
  dispatchPointIdentifier?: string;
  stampId?: number;
  stampIdentifier?: string;
  stampBeginDate?: string;
  status?: string;
  taxPayerRuc?: string;
  taxPayerSocialName?: string;
  taxPayerFantasyName?: string;
  clientId?: number;
  clientCode?: string;
  clientIsTaxPayer?: boolean;
  clientRuc?: string;
  clientSocialName?: string;
  clientFantasyName?: string;
  clientType?: string;
  clientCountryCode?: string;
  clientHasAddress?: boolean;
  clientAddress?: string;
  clientHouseNumber?: number;
  clientDepartment?: number;
  clientDepartmentDescription?: string;
  clientDistrict?: number;
  clientDistrictDescription?: string;
  clientCity?: number;
  clientCityDescription?: string;
  clientPhoneNumber?: string;
  clientEmail?: string;
  receiptDate?: string;
  receiptNumber?: string;

  associatedDocumentType?: string;
  invoiceId?: number;
  invoiceCdc?: string;
  associatedDocumentPrintedType?: string;
  associatedDocumentStampIdentifier?: string;
  associatedDocumentEstablishmentCode?: string;
  associatedDocumentDispatchPointCode?: string;
  associatedDocumentNumber?: string;
  associatedDocumentDate?: string;

  currency?: string;
  description?: string;
  paymentMethod?: string;
  checkNumber?: string;
  checkBank?: string;
  obs?: string;
  amount?: number;

  pdfPath?: string;

  createdAt?: string;
  updatedAt?: string;
}

export const formatReceiptNumber = (receipt: Receipt): string => {
  return `${receipt.establishmentCode}-${receipt.dispatchPointIdentifier}-${receipt.receiptNumber}`;
};

export const newReceipt = (taxPayerId?: number): Receipt => {
  return { taxPayerId: taxPayerId };
};
