import { useSelector } from "react-redux";
import { RootState } from "../../store";
import React, { useEffect, useRef, useState } from "react";
import Errors, { getFieldErrors } from "../../models/errors";
import Client from "../../models/client";
import { useHistory } from "react-router-dom";
import { errorAlert, successAlert } from "../utils/messages";
import Establishment from "../../models/establishment";
import {
  ItemRequestStatus,
  createItem,
  getItem,
  updateItem,
} from "../../api/generics";
import { NOT_FOUND, SUCCESS } from "../../utils/constants/tags";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from "@coreui/react";

import "react-datetime/css/react-datetime.css";
import moment from "moment";

import EstablishmentSelect from "../establishments/EstablishmentSelect";
import { FieldErrors } from "../form/FieldErrors";
import ClientSelect from "../client/ClientSelect";
import Loading from "../indicators/Loading";
import ClientUpdateModal from "../client/ClientUpdateModal";
import Stamp from "../../models/stamp";
import StampSelect from "../stamps/StampSelect";
import { CURRENCY_CHOICES, PYG } from "../../currency/available-currencies";
import CurrencyField from "../currencies/CurrencyField";
import { Spinner } from "react-bootstrap";
import { getRoutePath } from "../../routes";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointSelect from "../dispatch-point/DispatchPointSelect";
import {
  ASSOCIATED_DOCUMENT_TYPE_CHOICES,
  ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE,
  ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE,
} from "../../models/associated-document";
import { INVOICE_STATUS_APPROVED_BY_SET, Invoice } from "../../models/invoice";
import InvoiceSelect from "../invoice/InvoiceSelect";
import { getStampById } from "../../api/stamp";
import { getEstablishmentById } from "../../api/establishment";
import { getDispatchPointById } from "../../api/dispatch-point";
import { getClientById } from "../../api/client";
import NotFound from "../indicators/NotFound";
import ServerError from "../indicators/ServerError";
import { getInvoiceById } from "../../api/invoice";

import Datetime from "react-datetime";

import { changeTZToUtc } from "../../utils/dates";
import {
  RECEIPT_PAYMENT_METHOD_CASH,
  RECEIPT_PAYMENT_METHOD_CHECK,
  RECEIPT_PAYMENT_METHOD_CHOICES,
  Receipt,
  newReceipt,
} from "../../models/receipt";

const ReceiptForm = () => {
  const name = "Agregar Recibo";

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [loadError, setLoadError] = useState(false);
  const [loadNotFound, setLoadNotFound] = useState(false);

  const receiptRef = useRef<Receipt | null>(newReceipt(taxPayer.id));
  const [receiptDate, setReceiptDate] = useState<Date | undefined>(undefined);

  const [associatedDocumentType, setAssociatedDocumentType] = useState<
    string | undefined
  >(undefined);

  const [electronicInvoice, setElectronicInvoice] = useState<Invoice | null>(
    null
  );

  const [currency, setCurrency] = useState(PYG);
  const [paymentMethod, setPaymentMethod] = useState(
    RECEIPT_PAYMENT_METHOD_CASH
  );
  const [totalAmount, setTotalAmount] = useState<number | undefined>(undefined);

  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );

  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | null>(
    null
  );

  const [stamp, setStamp] = useState<Stamp | null>(null);

  const [client, setClient] = useState<Client | null>(null);
  const [showAddClient, setShowAddClient] = useState(false);

  const history = useHistory();

  const getEditingReceiptId = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const editingReceiptId = urlParams.get("id");

    if (editingReceiptId !== null) {
      if (!isNaN(parseInt(editingReceiptId))) {
        return parseInt(editingReceiptId);
      }
    }

    return undefined;
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.establishmentId = newEstablishment?.id;
    receiptRef.current.establishmentCode = newEstablishment?.code;

    setEstablishment(newEstablishment);
  };

  const onDispatchPointChange = (newDispatchPoint: DispatchPoint | null) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.dispatchPointId = newDispatchPoint?.id;
    receiptRef.current.dispatchPointIdentifier = newDispatchPoint?.identifier;

    setDispatchPoint(newDispatchPoint);
  };

  const onStampChange = (newStamp: Stamp | null) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.stampId = newStamp?.id;
    receiptRef.current.stampIdentifier = newStamp?.identifier;
    receiptRef.current.stampBeginDate = newStamp?.beginDate;

    setStamp(newStamp);
  };

  const onClientChange = (newClient: Client | null) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.clientId = newClient?.id;
    receiptRef.current.clientCode = newClient?.code;
    receiptRef.current.clientIsTaxPayer = newClient?.isTaxPayer;
    receiptRef.current.clientRuc = newClient?.ruc;
    receiptRef.current.clientSocialName = newClient?.socialName;
    receiptRef.current.clientFantasyName = newClient?.fantasyName;
    receiptRef.current.clientType = newClient?.type;
    receiptRef.current.clientCountryCode = newClient?.countryCode;
    receiptRef.current.clientHasAddress = newClient?.hasAddress;
    receiptRef.current.clientAddress = newClient?.address;
    receiptRef.current.clientHouseNumber = newClient?.houseNumber;
    receiptRef.current.clientDepartment = newClient?.department;
    receiptRef.current.clientDepartmentDescription =
      newClient?.departmentDescription;
    receiptRef.current.clientDistrict = newClient?.district;
    receiptRef.current.clientDistrictDescription =
      newClient?.districtDescription;
    receiptRef.current.clientCity = newClient?.city;
    receiptRef.current.clientCityDescription = newClient?.cityDescription;
    receiptRef.current.clientPhoneNumber = newClient?.phoneNumber;
    receiptRef.current.clientEmail = newClient?.email;

    setClient(newClient);
  };

  const onReceiptDateChange = (value: string | moment.Moment) => {
    if (receiptRef.current === null) {
      return;
    }

    const newDate = value as moment.Moment;

    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const receiptDate = new Date((value as moment.Moment).toISOString());
    setReceiptDate(receiptDate);

    receiptRef.current.receiptDate = receiptDate.toISOString();
  };

  const onAssociatedDocumentTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (receiptRef.current === null) {
      return;
    }

    const newAssociatedDocumentType = e.target.value;

    receiptRef.current.associatedDocumentType = newAssociatedDocumentType;

    if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE
    ) {
      receiptRef.current.associatedDocumentPrintedType = undefined;
      receiptRef.current.associatedDocumentStampIdentifier = undefined;
      receiptRef.current.associatedDocumentEstablishmentCode = undefined;
      receiptRef.current.associatedDocumentDispatchPointCode = undefined;
      receiptRef.current.associatedDocumentNumber = undefined;
      receiptRef.current.associatedDocumentDate = undefined;
    } else if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE
    ) {
      receiptRef.current.invoiceCdc = undefined;
      receiptRef.current.invoiceId = undefined;
      receiptRef.current.associatedDocumentPrintedType =
        ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;
      setElectronicInvoice(null);
    }

    setAssociatedDocumentType(e.target.value);
  };

  const onElectronicInvoiceChange = (newElectronicInvoice: Invoice | null) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.invoiceId = newElectronicInvoice?.id;
    receiptRef.current.invoiceCdc = newElectronicInvoice?.cdc;

    setElectronicInvoice(newElectronicInvoice);
  };

  const onAssociatedDocumentStampIdentifierChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.associatedDocumentStampIdentifier = e.target.value;
  };

  const onAssociatedDocumentEstablishmentCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.associatedDocumentEstablishmentCode = e.target.value;
  };

  const onAssociatedDocumentDispatchPointCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.associatedDocumentDispatchPointCode = e.target.value;
  };

  const onAssociatedDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.associatedDocumentNumber = e.target.value;
  };

  const onAssociatedDocumentDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.associatedDocumentDate = e.target.value;
  };

  const onCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.currency = e.target.value;
    setCurrency(e.target.value);
  };

  const onPaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.paymentMethod = e.target.value;
    setPaymentMethod(e.target.value);
  };

  const onCheckNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.checkNumber = e.target.value;
  };

  const onCheckBankChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.checkBank = e.target.value;
  };

  const onTotalAmountChange = (newAmount: number | undefined) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.amount = newAmount;
    setTotalAmount(newAmount);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (receiptRef.current === null) {
      return;
    }

    receiptRef.current.description = e.target.value;
  };

  const onAddClientClick = () => {
    setShowAddClient(true);
  };

  const onAddClientSuccess = (newClient?: Client) => {
    let message = "Cliente agregado correctamente";
    onClientChange(newClient ? newClient : null);
    if (newClient) {
      message = `Cliente ${newClient.socialName} agregado correctamente`;
    }
    successAlert(message);
    setShowAddClient(false);
  };

  const onAddClientCancel = () => {
    setShowAddClient(false);
  };

  const onSave = async () => {
    if (receiptRef.current === null) {
      return;
    }

    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<Receipt>>;
    let successMessage = "Recibo agregada exitósamente";

    const receipt = receiptRef.current;
    receipt.taxPayerRuc = taxPayer.ruc;
    receipt.taxPayerSocialName = taxPayer.socialName;
    receipt.taxPayerFantasyName = taxPayer.fantasyName;

    if (receipt.associatedDocumentType === undefined) {
      receipt.associatedDocumentType =
        ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE;
    }

    if (receipt.currency === undefined) {
      receipt.currency = PYG;
    }

    if (receipt.paymentMethod === undefined) {
      receipt.paymentMethod = RECEIPT_PAYMENT_METHOD_CASH;
    }

    if (receipt.id === undefined) {
      requestPromise = createItem<Receipt>("/receipts/create/", receipt);
    } else {
      requestPromise = updateItem<Receipt>(`/receipts/${receipt.id}/`, receipt);
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";

      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      successAlert(successMessage);
      history.push(getRoutePath("receipts"));
    }
  };

  const loadInitialReceiptById = async (): Promise<Receipt | null> => {
    const editingId = getEditingReceiptId();

    if (editingId === undefined) {
      return null;
    }

    let requestStatus = await getItem<Receipt>(`/receipts/${editingId}/`);

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.status === NOT_FOUND) {
        setLoadNotFound(true);
      } else {
        setLoadError(true);
      }

      return null;
    }

    if (requestStatus.data === undefined || requestStatus.data === null) {
      setLoadError(true);
      return null;
    }

    return requestStatus.data!;
  };

  const loadInitialData = async () => {
    setLoading(true);
    const receipt = await loadInitialReceiptById();

    if (receipt === null) {
      setLoading(false);
      return;
    }

    const stampPromise = getStampById(receipt.stampId);
    const establishmentPromise = getEstablishmentById(receipt.establishmentId);
    const dispatchPointPromise = getDispatchPointById(receipt.dispatchPointId);
    const clientPromise = getClientById(receipt.clientId);

    let invoicePromise: Promise<Invoice | null> | null = null;

    if (receipt.invoiceId !== undefined) {
      invoicePromise = getInvoiceById(receipt.invoiceId);
    }

    const stamp = await stampPromise;
    const establishment = await establishmentPromise;
    const dispatchPoint = await dispatchPointPromise;
    const client = await clientPromise;

    receiptRef.current = receipt;

    setReceiptDate(new Date(receipt.receiptDate!));

    setStamp(stamp);
    setEstablishment(establishment);
    setDispatchPoint(dispatchPoint);
    setClient(client);

    if (invoicePromise !== null) {
      const previousInvoice = await invoicePromise;
      setElectronicInvoice(previousInvoice);
    }

    setTotalAmount(receipt.amount!);

    setAssociatedDocumentType(receipt.associatedDocumentType);

    setLoading(false);
  };

  useEffect(() => {
    const newReceiptDate = changeTZToUtc(new Date());
    if (receiptRef.current !== null) {
      receiptRef.current.receiptDate = newReceiptDate.toISOString();
      setReceiptDate(newReceiptDate);
    }
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showElectronicInvoiceSelect =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE;
  const showPrintedInvoiceFields =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;

  if (loading) {
    return <Loading />;
  }

  if (loadNotFound) {
    return <NotFound />;
  }

  if (loadError) {
    return <ServerError />;
  }

  return (
    <>
      <CCard>
        <CCardHeader>{name}</CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Timbrado:
                </CLabel>
              </CCol>
              <CCol>
                <StampSelect onChange={onStampChange} value={stamp} />
                <FieldErrors
                  errors={getFieldErrors("stampId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Establecimiento:
                </CLabel>
              </CCol>
              <CCol>
                <EstablishmentSelect
                  onChange={onEstablishmentChange}
                  value={establishment}
                />
                <FieldErrors
                  errors={getFieldErrors("establishmentId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Punto de Expedición:
                </CLabel>
              </CCol>
              <CCol>
                <DispatchPointSelect
                  onChange={onDispatchPointChange}
                  value={dispatchPoint}
                />
                <FieldErrors
                  errors={getFieldErrors("dispatchPointId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Cliente:
                </CLabel>
              </CCol>
              <CCol>
                <CInputGroup>
                  <ClientSelect
                    value={client}
                    onChange={onClientChange}
                  ></ClientSelect>
                  <CInputGroupAppend>
                    <CButton
                      className="btn btn-primary"
                      // Prevent button on top
                      style={{ zIndex: 0 }}
                      onClick={onAddClientClick}
                    >
                      <i className="fa fa-plus"></i>
                    </CButton>
                  </CInputGroupAppend>
                </CInputGroup>
                <FieldErrors
                  errors={getFieldErrors("clientId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Fecha de emisión:
                </CLabel>
              </CCol>
              <CCol>
                <Datetime
                  className="pl-0"
                  onChange={onReceiptDateChange}
                  value={receiptDate}
                  locale="es/PY"
                  utc
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                ></Datetime>
                <FieldErrors
                  errors={getFieldErrors("receiptDate", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Tipo Documento
                  Asociado:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    receiptRef.current
                      ? receiptRef.current.associatedDocumentType
                      : undefined
                  }
                  onChange={onAssociatedDocumentTypeChange}
                >
                  <option value={""}>-----</option>
                  {Array.from(ASSOCIATED_DOCUMENT_TYPE_CHOICES.entries()).map(
                    (entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    }
                  )}
                </CSelect>
                <FieldErrors
                  errors={
                    getFieldErrors("associatedDocumentType", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          {showElectronicInvoiceSelect ? (
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Factura Electrónica:
                  </CLabel>
                </CCol>
                <CCol>
                  <InvoiceSelect
                    onChange={onElectronicInvoiceChange}
                    value={electronicInvoice}
                    status={INVOICE_STATUS_APPROVED_BY_SET}
                  />
                  <FieldErrors
                    errors={getFieldErrors("invoiceId", errors) as string[]}
                  ></FieldErrors>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "associatedDocumentCdc",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          ) : (
            <></>
          )}
          {showPrintedInvoiceFields ? (
            <>
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Timbrado:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        receiptRef.current
                          ? receiptRef.current.associatedDocumentStampIdentifier
                          : undefined
                      }
                      onChange={onAssociatedDocumentStampIdentifierChange}
                      placeholder="Timbrado"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentStampIdentifier",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Establecimiento:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        receiptRef.current
                          ? receiptRef.current
                              .associatedDocumentEstablishmentCode
                          : undefined
                      }
                      onChange={onAssociatedDocumentEstablishmentCodeChange}
                      placeholder="Cod"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentEstablishmentCode",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Punto Expedición:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        receiptRef.current
                          ? receiptRef.current
                              .associatedDocumentDispatchPointCode
                          : undefined
                      }
                      onChange={onAssociatedDocumentDispatchPointCodeChange}
                      placeholder="Cod"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentDispatchPointCode",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Nro. Factura:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        receiptRef.current
                          ? receiptRef.current.associatedDocumentNumber
                          : undefined
                      }
                      onChange={onAssociatedDocumentNumberChange}
                      placeholder="Nro"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentNumber",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Fecha Factura:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="date"
                      defaultValue={
                        receiptRef.current
                          ? receiptRef.current.associatedDocumentDate
                          : undefined
                      }
                      onChange={onAssociatedDocumentDateChange}
                      placeholder="Fecha"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentDate",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
            </>
          ) : (
            <></>
          )}
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Moneda:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    receiptRef.current !== null
                      ? receiptRef.current.currency
                      : PYG
                  }
                  onChange={onCurrencyChange}
                >
                  <option value={""} disabled>
                    -----
                  </option>
                  {Array.from(CURRENCY_CHOICES.entries()).map((entry) => {
                    return (
                      <option key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </option>
                    );
                  })}
                </CSelect>
                <FieldErrors
                  errors={getFieldErrors("currency", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Forma de Pago:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    receiptRef.current
                      ? receiptRef.current.paymentMethod
                      : undefined
                  }
                  onChange={onPaymentMethodChange}
                >
                  <option value={""}>-----</option>
                  {Array.from(RECEIPT_PAYMENT_METHOD_CHOICES.entries()).map(
                    (entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    }
                  )}
                </CSelect>
                <FieldErrors
                  errors={getFieldErrors("paymentMethod", errors) as string[]}
                ></FieldErrors>
              </CCol>
              {paymentMethod === RECEIPT_PAYMENT_METHOD_CHECK ? (
                <>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Nº Cheque:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        receiptRef.current
                          ? receiptRef.current.checkNumber
                          : undefined
                      }
                      onChange={onCheckNumberChange}
                      placeholder="Nro"
                    ></CInput>
                    <FieldErrors
                      errors={getFieldErrors("checkNumber", errors) as string[]}
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Banco Cheque:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        receiptRef.current
                          ? receiptRef.current.checkBank
                          : undefined
                      }
                      onChange={onCheckBankChange}
                      placeholder="Banco"
                    ></CInput>
                    <FieldErrors
                      errors={getFieldErrors("checkBank", errors) as string[]}
                    ></FieldErrors>
                  </CCol>
                </>
              ) : (
                <></>
              )}
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Total:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total"
                  limit={1000000000000}
                  disabled={false}
                  onChange={onTotalAmountChange}
                  value={totalAmount}
                />
                <FieldErrors
                  errors={getFieldErrors("amount", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Descripción:
                </CLabel>
              </CCol>
              <CCol md={10}>
                <CTextarea
                  placeholder="Descripción"
                  defaultValue={
                    receiptRef.current
                      ? receiptRef.current.description
                      : undefined
                  }
                  onChange={onDescriptionChange}
                ></CTextarea>
                <FieldErrors
                  errors={getFieldErrors("description", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        </CCardBody>
        <CCardFooter>
          <CButton type="submit" color="primary" onClick={onSave}>
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Guardando..." : "Guardar"}
          </CButton>
        </CCardFooter>
      </CCard>
      <ClientUpdateModal
        show={showAddClient}
        onSuccess={onAddClientSuccess}
        onCancel={onAddClientCancel}
      />
    </>
  );
};

export default ReceiptForm;
