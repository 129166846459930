import {
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CTextarea,
} from "@coreui/react";
import React, { useCallback, useEffect, useState } from "react";
import Errors, { errorsAreSame, getFieldErrors } from "../../models/errors";
import { InvoiceItem } from "../../models/invoice";
import CurrencyField from "../currencies/CurrencyField";
import { FieldErrors } from "../form/FieldErrors";
import { emptyValueOnUndefined } from "../../utils/fields";
import { AutoInvoiceItem } from "../../models/auto-invoice";

const addTotalAmount = (
  item: AutoInvoiceItem,
  unitAmount?: number,
  quantity?: number
): InvoiceItem => {
  if (unitAmount !== undefined && quantity !== undefined) {
    item.totalAmount = unitAmount * quantity;
  }

  return item;
};

const addMissingValues = (item: InvoiceItem) => {
  const newItem = { ...item };
  // TODO: see how to handle this
  newItem.measureUnit = 77;

  return newItem;
};

interface AutoInvoiceItemFormProps {
  item: AutoInvoiceItem;
  currency: string;
  onDelete: (_: InvoiceItem) => void;
  onChange: (_: InvoiceItem) => void;
  errors: Errors;
}

const AutoInvoiceItemForm: React.FC<AutoInvoiceItemFormProps> = ({
  item,
  currency,
  onDelete,
  onChange,
  errors,
}) => {
  const [editingItem, setEditingItem] = useState(item);
  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newItem = { ...editingItem };
      if (!isNaN(parseInt(e.target.value))) {
        newItem.quantity = parseInt(e.target.value);
        newItem = addTotalAmount(
          newItem,
          newItem.unitAmount,
          parseInt(e.target.value)
        );
      } else {
        newItem.quantity = undefined;
        newItem = addTotalAmount(newItem, newItem.unitAmount, undefined);
      }

      newItem = addMissingValues(newItem);

      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newItem = { ...editingItem };
      newItem.code = e.target.value;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newItem = { ...editingItem };
      newItem.description = e.target.value;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onUnitAmountChange = useCallback(
    (newUnitAmount?: number) => {
      let newItem = { ...editingItem };
      newItem.unitAmount = newUnitAmount;
      newItem = addTotalAmount(newItem, newUnitAmount, newItem.quantity);
      newItem = addMissingValues(newItem);
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onDiscountChange = useCallback(
    (newDiscount?: number) => {
      let newItem = { ...editingItem };
      newItem.discount = newDiscount;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  useEffect(() => {
    setEditingItem(item);
  }, [item]);

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Cantidad:
            </CLabel>
          </CCol>

          <CCol md={2}>
            <CInput
              type="number"
              value={emptyValueOnUndefined(editingItem.quantity)}
              onChange={onQuantityChange}
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("quantity", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Cod. Ítem:
            </CLabel>
          </CCol>

          <CCol md={2}>
            <CInput
              type="text"
              value={emptyValueOnUndefined(editingItem.code)}
              onChange={onCodeChange}
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("code", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Detalle:
            </CLabel>
          </CCol>
          <CCol md={10}>
            <CTextarea
              placeholder="Detalle del ítem"
              value={emptyValueOnUndefined(editingItem.description)}
              onChange={onDescriptionChange}
            ></CTextarea>
            <FieldErrors
              errors={getFieldErrors("description", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Precio Unitario:
            </CLabel>
          </CCol>

          <CCol md={2}>
            <CurrencyField
              currency={currency}
              placeholder="Introduzca el monto"
              onChange={onUnitAmountChange}
              value={editingItem.unitAmount}
              limit={1000000000000}
            />
            <FieldErrors
              errors={getFieldErrors("unitAmount", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={2}>
            <CLabel>Descuento:</CLabel>
          </CCol>

          <CCol md={2}>
            <CurrencyField
              currency={currency}
              placeholder="Introduzca el monto"
              onChange={onDiscountChange}
              value={editingItem.discount}
              limit={1000000000000}
            />
            <FieldErrors
              errors={getFieldErrors("discount", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CRow className="mt-2">
        <CCol>
          <CButton
            className="btn btn-danger float-right"
            onClick={onDeleteClick}
          >
            <i className="fa fa-trash"></i>
          </CButton>
        </CCol>
      </CRow>
    </div>
  );
};

const propsAreEqual = (
  prevItemProps: AutoInvoiceItemFormProps,
  nextItemProps: AutoInvoiceItemFormProps
): boolean => {
  return (
    prevItemProps.item.id === nextItemProps.item.id &&
    prevItemProps.item.obs === nextItemProps.item.obs &&
    prevItemProps.item.measureUnit === nextItemProps.item.measureUnit &&
    prevItemProps.item.unitAmount === nextItemProps.item.unitAmount &&
    prevItemProps.item.description === nextItemProps.item.description &&
    prevItemProps.item.quantity === nextItemProps.item.quantity &&
    prevItemProps.item.totalAmount === nextItemProps.item.totalAmount &&
    errorsAreSame(prevItemProps.errors, nextItemProps.errors)
  );
};

export default React.memo(AutoInvoiceItemForm, propsAreEqual);
