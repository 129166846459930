import { CPopover } from "@coreui/react";
import {
  REMISSION_NOTE_STATUS_APPROVED_BY_SET,
  REMISSION_NOTE_STATUS_CANCELLATION_FAILED,
  REMISSION_NOTE_STATUS_CANCELLATION_REQUESTED,
  REMISSION_NOTE_STATUS_CANCELLED,
  REMISSION_NOTE_STATUS_CHOICES,
  REMISSION_NOTE_STATUS_ERROR,
  REMISSION_NOTE_STATUS_RESEND_REQUESTED,
  REMISSION_NOTE_STATUS_SET_UNKNOWN_ERROR,
  REMISSION_NOTE_STATUS_UPLOADED_TO_SET,
  RemissionNote,
} from "../../models/remission-note";
import { useEffect, useState } from "react";
import { useSub } from "../../hooks/pub-sub";

interface RemissionNoteCrudStatusProps {
  remissionNote: RemissionNote;
}

const RemissionNoteCrudStatus: React.FC<RemissionNoteCrudStatusProps> = ({
  remissionNote,
}) => {
  const [currentRemissionNote, setCurrentRemissionNote] =
    useState(remissionNote);

  useSub(
    `remission_note_${remissionNote.id}`,
    (remissionNote: RemissionNote) => {
      setCurrentRemissionNote(remissionNote);
    }
  );

  useEffect(() => {
    setCurrentRemissionNote(remissionNote);
  }, [remissionNote]);

  let badgeClassName = "badge-info";

  if (currentRemissionNote.status === REMISSION_NOTE_STATUS_UPLOADED_TO_SET) {
    badgeClassName = "badge-warning";
  }

  if (currentRemissionNote.status === REMISSION_NOTE_STATUS_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentRemissionNote.status === REMISSION_NOTE_STATUS_SET_UNKNOWN_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentRemissionNote.status === REMISSION_NOTE_STATUS_APPROVED_BY_SET) {
    badgeClassName = "badge-success";
  }

  if (
    currentRemissionNote.status === REMISSION_NOTE_STATUS_CANCELLATION_REQUESTED
  ) {
    badgeClassName = "badge-light";
  }

  if (currentRemissionNote.status === REMISSION_NOTE_STATUS_CANCELLED) {
    badgeClassName = "badge-info";
  }

  if (
    currentRemissionNote.status === REMISSION_NOTE_STATUS_CANCELLATION_FAILED
  ) {
    badgeClassName = "badge-danger";
  }

  if (currentRemissionNote.status === REMISSION_NOTE_STATUS_RESEND_REQUESTED) {
    badgeClassName = "badge-warning";
  }

  return (
    <td className="text-center">
      <span
        className={`badge badge-pill ${badgeClassName}`}
        style={{ fontSize: "14px" }}
      >
        {REMISSION_NOTE_STATUS_CHOICES.get(currentRemissionNote.status!)}{" "}
        {currentRemissionNote.status === REMISSION_NOTE_STATUS_ERROR ||
        currentRemissionNote.status ===
          REMISSION_NOTE_STATUS_CANCELLATION_FAILED ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Código: {currentRemissionNote.setResponseCode}</li>
                <li>
                  Descripción: {currentRemissionNote.setResponseDescription}
                </li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
        {currentRemissionNote.status ===
        REMISSION_NOTE_STATUS_SET_UNKNOWN_ERROR ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Error: {currentRemissionNote.setError}</li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
      </span>
    </td>
  );
};

export default RemissionNoteCrudStatus;
